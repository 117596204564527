export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const TS_API_BASE_URL = process.env.NEXT_PUBLIC_TS_API_BASE_URL;

export const APP_BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL;

export const FIREBASE_KEYS = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

export const FIREBASE_VAPID_KEY = process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY;

export const CATEGORY_IDS = {
  "config-dos-pro": process.env.NEXT_PUBLIC_CATEGORY_IDS_CONFIG_DOS_PRO,
  cs2: process.env.NEXT_PUBLIC_CATEGORY_IDS_CS2,
  "cs-atualizacoes": process.env.NEXT_PUBLIC_CATEGORY_IDS_CS_ATUALIZACOES,
  "gamers-club": process.env.NEXT_PUBLIC_CATEGORY_IDS_GAMERS_CLUB,
  destaque: process.env.NEXT_PUBLIC_CATEGORY_IDS_DESTAQUE,
  patrocinado: process.env.NEXT_PUBLIC_CATEGORY_IDS_PATROCINADO,
  "ao-vivo": process.env.NEXT_PUBLIC_CATEGORY_IDS_AOVIVO,
  podcast: process.env.NEXT_PUBLIC_CATEGORY_IDS_PODCAST,
  colunas: process.env.NEXT_PUBLIC_CATEGORY_IDS_COLUNAS,
  fixado: process.env.NEXT_PUBLIC_CATEGORY_IDS_FIXADO,
  ofertas: process.env.NEXT_PUBLIC_CATEGORY_IDS_OFERTAS,
};

export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

export const S3_BASE_URL = process.env.NEXT_PUBLIC_S3_BASE_URL;

export const USER_AVATAR_PLACEHOLDER =
  process.env.NEXT_PUBLIC_USER_AVATAR_PLACEHOLDER;

export const NEWS_START_DATE = process.env.NEXT_PUBLIC_NEWS_START_DATE;
export const SPONSORED_NEWS_CATEGORY_SLUG =
  process.env.NEXT_PUBLIC_SPONSORED_NEWS_CATEGORY_SLUG;
export const LIVE_NEWS_CATEGORY_SLUG =
  process.env.NEXT_PUBLIC_LIVE_NEWS_CATEGORY_SLUG;
export const FIXED_NEWS_CATEGORY_SLUG =
  process.env.NEXT_PUBLIC_FIXED_NEWS_CATEGORY_SLUG;

export const PRIORITIZED_ONBOARDING_TEAMS = (
  process.env.NEXT_PUBLIC_PRIORITIZED_ONBOARDING_TEAMS || ""
).split(",");
export const PRIORITIZED_ONBOARDING_TOURNAMENTS = (
  process.env.NEXT_PUBLIC_PRIORITIZED_ONBOARDING_TOURNAMENTS || ""
).split(",");

export const STEAM_LOGIN_URL = process.env.NEXT_PUBLIC_STEAM_LOGIN_URL;

export const TEAM_LOGO_PLACEHOLDER =
  "https://static.draft5.gg/assets/team-logo-placeholder.png";

export const GCCF_LIB_URL = process.env.NEXT_PUBLIC_GCCF_LIB_URL;
