import React from "react";

const Sales = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296.41 296.41" version="1.1">
    <path d="m268.115,109.241l-.007-48.16-45.821-14.862-28.274-39.001-45.808,14.944-45.809-14.943-28.273,39-45.821,14.862-.007,48.161-28.295,38.963 28.296,38.964 .006,48.16 45.821,14.862 28.274,39.001 45.808-14.944 45.809,14.943 28.273-39 45.821-14.862 .007-48.161 28.295-38.963-28.295-38.964zm-171.548,25.239c-4.941-4.971-7.695-11.228-7.695-18.772v-6.753c0-7.602 2.739-13.903 7.651-18.903 4.913-4.999 12.041-7.498 21.106-7.498 9.18,0 16.095,2.499 21.006,7.498 4.913,5 7.237,11.302 7.237,18.903v6.753c0,7.603-2.254,13.874-7.167,18.813-4.912,4.943-11.94,7.412-21.003,7.412-9.122,0.001-16.193-2.484-21.135-7.453zm26.026,68.062l-13.77-6.93 62.363-99.814 13.772,6.93-62.365,99.814zm84.279-14.997c0,7.66-2.392,13.961-7.275,18.902-4.883,4.941-11.83,7.409-20.894,7.409-9.121,0-16.257-2.483-21.258-7.454-4.997-4.97-7.573-11.256-7.573-18.857v-6.754c0-7.543 2.573-13.815 7.544-18.815 4.969-5.001 12.031-7.499 21.093-7.499 9.18,0 16.183,2.487 21.066,7.454 4.883,4.972 7.297,11.258 7.297,18.86v6.754z"/>
    <path d="m124.645,122.965c1.608-1.947 2.227-4.373 2.227-7.279v-6.711c0-2.903-0.635-5.357-2.272-7.362-1.639-2.005-3.884-3.008-6.924-3.008-2.981,0-5.504,1.003-7.14,3.008-1.637,2.005-2.664,4.459-2.664,7.362v6.711c0,2.906 1.074,5.332 2.71,7.279 1.637,1.945 4.041,2.918 7.142,2.918 2.981-1.42109e-14 5.312-0.973 6.921-2.918z"/>
    <path d="m178.554,170.527c-3.041,0-5.485,1.005-7.12,3.008-1.641,2.005-2.562,4.432-2.562,7.278v6.708c0,2.792 1.039,5.201 2.912,7.236 1.871,2.033 4.203,3.048 6.893,3.048 3.625,0 6.03-0.899 7.318-2.702 1.284-1.8 1.877-4.327 1.877-7.582v-6.708c0-2.847-0.755-5.273-2.422-7.278-1.667-2.003-3.915-3.008-6.896-3.008z"/>
    </svg>
  );
};

export default Sales;
